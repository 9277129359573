/** @format */

import Dialog from '@atoms/Dialog';
import Modal from '@atoms/Modal';

import useLocalStorage from '@common/application/hooks/useLocalStorage/useLocalStorage';
import useOnClickOutside from '@common/application/hooks/useOnClickOutside/src';
import styled from '@emotion/styled';
import Image from 'next/image';
import router from 'next/router';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import UpdateImg from '@common/application/assets/images/tempImages/update.png';
import UpdateFrenchImg from '@common/application/assets/images/tempImages/updateFrench.png';
import UpdateGermanImg from '@common/application/assets/images/tempImages/updateGerman.png';
import UpdateDutchImg from '@common/application/assets/images/tempImages/updateDutch.png';
import UpdateAustrianImg from '@common/application/assets/images/tempImages/updateAustrian.png';

interface WhatsNewModalProps {
  isShown: boolean;
  setIsShown(value: boolean): void;
}

const ImageWrapper = styled.div`
  //width: 1000px;
  //height: 500px;
  aspect-ratio: 2/1;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 98%;
  margin: 0 auto;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  z-index: 9;
  svg {
    height: 30px;
    width: 30px;
  }
`;

function WhatsNewModal(props: WhatsNewModalProps) {
  const [imgSrc, setImgSrc] = useState('');
  const [showCount, setShowCount] = useLocalStorage('whatsNew3.2', 0);
  const ref = useRef();
  const { locale } = router;
  const maxShowCount = 5;
  const closeModal = () => {
    props.setIsShown(true);
    setShowCount(showCount + 1);
  };

  useOnClickOutside(ref, () => closeModal());

  const getSrc = () => {
    const lang = locale.split('-')[0];
    switch (lang) {
      case 'fr':
        // FRENCH
        return UpdateFrenchImg.src;
      case 'de':
        if (locale.split('-')[1] === 'AT') {
          //   Austria
          return UpdateAustrianImg.src;
        }
        // Germany
        return UpdateGermanImg.src;
      case 'nl':
        return UpdateDutchImg.src;
      default:
        // return UpdateFrenchImg.src;
        return UpdateImg.src;
    }
  };

  useEffect(() => {
    const src = getSrc();
    setImgSrc(src);
  }, []);

  if (props.isShown || showCount > 4) {
    return null;
  }

  return (
    <Modal
      state={{
        // isOpen: true,
        isOpen: !props.isShown,
        close: () => props.setIsShown(false),
        open: () => props.setIsShown(true),
        setOpen: (bool) => props.setIsShown(!bool),
        // @ts-ignore
        toggle: () => props.setIsShown((b) => !b),
      }}
      isDismissable={true}
    >
      <Dialog onClose={() => props.setIsShown(false)} title={''}>
        <ImageWrapper>
          <Image src={imgSrc} layout={'fill'} />
        </ImageWrapper>
      </Dialog>
    </Modal>
  );
}

export default WhatsNewModal;
